import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { ColorsEnum } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import React from 'react';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import ALink from 'components/basic/ALink';
import ContentContainer from 'components/basic/ContentContainer';
import Icon from 'components/basic/Icon';
import { IconTypes } from 'components/basic/Icon/types';
import { Body5, Caption, Title2 } from 'components/basic/Typography';
import s from 'components/layouts/Footer/Footer.module.scss';
import { colorsVariables } from 'components/layouts/Footer/constants';
import type { FooterTypes } from 'components/layouts/Footer/types';

const Footer: FC<FooterTypes> = ({
  color = ColorsEnum.purple,
  isLight = false,
}) => {
  const { clickAnalytics } = useAnalytics();

  let style = {};
  if (isLight) {
    style = {
      '--background': 'var(--light-blue-10)',
      '--text-color': 'var(--text-black-100)',
      '--alink-color': 'var(--text-black-100)',
    };
  } else {
    style = colorsVariables[color] as React.CSSProperties;
  }

  return (
    <Typograf>
      <div className={s.wrapper} style={style}>
        <ContentContainer grid className={s.content}>
          <div className={s.description}>
            <Icon icon={IconTypes.logo} className={s.logo} />
            <ALink href="https://sk.ru/">
              <Icon icon={IconTypes.logoSK} className={s.skLogo} />
            </ALink>
            <Title2 className={s.title}>
              Платёжный сервис с уникальной и умной технологией
            </Title2>
          </div>

          <div className={s.products}>
            <Caption className={s.alinkTitle}>Продукты</Caption>
            <ALink
              className={s.alinkItem}
              href={pagesUrls.banks}
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterBanks);
              }}
            >
              <Body5>а3.банки</Body5>
            </ALink>
            <ALink
              className={s.alinkItem}
              href={pagesUrls.paymentServices}
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterPaymentServices);
              }}
            >
              <Body5>а3.платёжные сервисы</Body5>
            </ALink>
            <ALink
              className={s.alinkItem}
              href={pagesUrls.providers}
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterProviders);
              }}
            >
              <Body5>а3.поставщики услуг</Body5>
            </ALink>
            <ALink
              className={s.alinkItem}
              href={pagesUrls.providerAccount}
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterProviderAccount);
              }}
            >
              <Body5>а3.лк поставщика</Body5>
            </ALink>
            <ALink
              className={s.alinkItem}
              href="https://www.a-3.ru/platezhi/"
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterPlatezhi);
              }}
            >
              <Body5>а3.оплата услуг</Body5>
            </ALink>
          </div>

          <div className={s.contacts}>
            <Caption className={s.alinkTitle}>Компания</Caption>
            <ALink
              className={s.alinkItem}
              href={pagesUrls.work}
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterWork);
              }}
            >
              <Body5>Работа у нас</Body5>
            </ALink>
            <ALink
              className={s.alinkItem}
              href={pagesUrls.contacts}
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterContacts);
              }}
            >
              <Body5>Контакты</Body5>
            </ALink>
          </div>

          <div className={s.engine}>
            <Caption className={s.alinkTitle}>а3.Engine</Caption>
            <ALink
              className={s.alinkItem}
              href={pagesUrls.aboutEngine}
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterEngine);
              }}
            >
              <Body5>О технологии</Body5>
            </ALink>
          </div>

          <Caption className={s.a3}>© 2025 ООО «Платёжный сервис А3»</Caption>
          <div className={s.number}>
            <ALink
              href="tel:88001003900"
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterA3Phone);
              }}
            >
              <Body5>8 800 100 39 00</Body5>
            </ALink>
          </div>
          <div className={s.madeBeta}>
            <ALink
              href="https://beta.ru/"
              onClick={(): void => {
                clickAnalytics(gtmEvents.clickFooterBetaSite);
              }}
            >
              <Caption>Design by Beta</Caption>
            </ALink>
          </div>
          <Caption className={cn(s.license, 'g-col-xss-8 g-col-m-12')}>
            ООО «Платёжный сервис А3» аккредитовано в качестве организации,
            осуществляющей деятельность в области информационных технологий, в
            марте 2022г. Компания оказывает услуги информационного и
            технологического обслуживания участникам расчётов (кредитным
            организациям, банковским платежным агентам, получателям платежей и
            плательщикам). Посредством программного обеспечения, созданного
            Компанией, осуществляется сбор, обработка и рассылка участникам
            расчётов информации по операциям, приём электронных платёжных
            документов, сформированных плательщиками, проверка целостности таких
            документов, их маршрутизация на оплату, приём ответов о проведении
            или отказе в проведении платежей, направление получателям платежей
            информации о результатах проведения платежей.
          </Caption>
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default Footer;
